<template>
  <div class="overflow-scroll">
    <div style="min-width: 1210px">
      <a-card
        :bordered="false"
        :tabList="tabListNoTitle"
        :activeTabKey="noTitleKey"
        @tabChange="key => onTabChange(key, 'noTitleKey')"
      >
        <a-card
          v-if="noTitleKey === 'baseDetail'"
          :bordered="false"
          style="margin-left: -32px"
        >
          <DescriptionList
            title="品牌信息"
            size="large"
            style="margin-top: -24px;margin-bottom: -48px"
          >
            <a-card
              :bordered="false"
              class="storeNewStoreLabelWidth6"
              style="margin-left: -16px"
            >
              <div class="table-operator">
                <a-form layout="inline" :form="form">
                  <a-row :gutter="5" style="line-height: 4">
                    <a-col :md="8" :sm="24">
                      <a-form-item label="店铺名称">
                        <a-input
                          style="width: 240px"
                          v-decorator="[
                            'storeName',
                            {
                              rules: [
                                {
                                  required: true,
                                  message: '请输入！'
                                }
                              ]
                            }
                          ]"
                          placeholder="请输入"
                        />
                      </a-form-item>
                    </a-col>
                    <a-col :md="8" :sm="24">
                      <a-form-item label="店铺类型">
                        <a-select
                          @select="getIsPerson"
                          style="width: 240px"
                          v-decorator="[
                            'storeType',
                            {
                              rules: [
                                {
                                  required: true,
                                  message: '请选择！'
                                }
                              ]
                            }
                          ]"
                          placeholder="请选择"
                        >
                          <a-select-option
                            v-for="item in storeTypeList"
                            :key="item.storeType"
                          >
                            {{ item.storeTypeShow }}
                          </a-select-option>
                        </a-select>
                      </a-form-item>
                    </a-col>
                    <a-col :md="8" :sm="24">
                      <a-form-item label="所在门店">
                        <a-select
                          showSearch
                          @change="getKitchen"
                          style="width: 240px"
                          v-decorator="[
                            'facilityId',
                            {
                              rules: [
                                {
                                  required: true,
                                  message: '请选择！'
                                }
                              ]
                            }
                          ]"
                          placeholder="请选择"
                        >
                          <a-select-option
                            v-for="item in facilityList"
                            :key="item.facilityId"
                          >
                            {{ item.facilityName }}
                          </a-select-option>
                        </a-select>
                      </a-form-item>
                    </a-col>
                    <a-col :md="8" :sm="24">
                      <a-form-item label="经营品类">
                        <a-select
                          style="width: 240px"
                          v-decorator="[
                            'cuisineType',
                            {
                              rules: [
                                {
                                  required: true,
                                  message: '请输入！'
                                }
                              ]
                            }
                          ]"
                          placeholder="请选择"
                        >
                          <a-select-option
                            v-for="item in cuisineTypeList"
                            :key="item.cuisineType"
                          >
                            {{ item.cuisineTypeShow }}
                          </a-select-option>
                        </a-select>
                      </a-form-item>
                    </a-col>
                    <a-col :md="8" :sm="24" v-if="isPerson">
                      <a-form-item label="客户名称">
                        <a-select
                          @change="getKitchen1"
                          style="width: 240px"
                          v-decorator="[
                            'tenantId',
                            {
                              rules: [
                                {
                                  required: true,
                                  message: '请选择！'
                                }
                              ]
                            }
                          ]"
                          placeholder="请选择"
                        >
                          <a-select-option
                            v-for="item in personList"
                            :key="item.tenantId"
                          >
                            {{ item.tenantName }}
                          </a-select-option>
                        </a-select>
                      </a-form-item>
                    </a-col>
                    <a-col :md="8" :sm="24" class="storeNewStoreLabelWidth">
                      <a-form-item label="关联档口">
                        <a-select
                          mode="multiple"
                          style="width: 240px"
                          v-decorator="[
                            'kitchenIdList',
                            {
                              rules: [
                                {
                                  required: true,
                                  message: '请选择！'
                                }
                              ]
                            }
                          ]"
                          placeholder="请选择"
                        >
                          <a-select-option
                            v-for="item in kitchList"
                            :key="item.kitchenId"
                          >
                            {{ item.kitchenCode }}
                          </a-select-option>
                        </a-select>
                      </a-form-item>
                    </a-col>
                  </a-row>
                </a-form>
              </div>
            </a-card>
          </DescriptionList>
        </a-card>
      </a-card>
      <div style="margin-top: 8px">
        <a-card :bordered="false">
          <div class="steps-action">
            <a-button @click="cancel">
              取消
            </a-button>
            <a-button type="primary" style="margin-left: 8px" @click="conserve">
              保存
            </a-button>
          </div>
        </a-card>
      </div>
    </div>
  </div>
</template>

<script>
import DescriptionList from "@/components/DescriptionList";
  
import Bus from "@/bus";
import axios from "axios";

export default {
  name: "shopNew",
  components: {
    DescriptionList
  },
  data() {
    return {
      tabListNoTitle: [
        {
          key: "baseDetail",
          tab: "基本信息"
        }
      ],
      noTitleKey: "baseDetail",
      form: this.$form.createForm(this),
      defaultData: "",

      //
      // 客户名称选择框是否显示
      isPerson: false,
      //经营品类
      cuisineTypeList: [],
      //所在门店
      facilityList: [],
      //店铺状态
      storeStatusList: [],
      //店铺类型
      storeTypeList: [],

      // 客户名称
      personList: [],
      // 关联档口
      kitchList: [],

      // 此时选中的门店
      nowFacility: ""
    };
  },
  created() {
    this.getInformation();
    Bus.$on("getTarget", target => {
      this.$router.push({
        path: "/role/shopList"
      });
    });
  },
  methods: {
    //获取所有枚举信息
    getInformation() {
      this.getCuisineType();
      this.getFacility();
      this.getStoreStatus();
      this.getStoreType();
    },
    //经营品类
    getCuisineType() {
      axios({
        url:   this.$baseUrl + "/tenantStore/allCuisineType",
        method: "GET"
      }).then(res => {
        if (res.data.rows) {
          this.cuisineTypeList = res.data.rows;
        }
      });
    },
    // 所在门店
    getFacility() {
      axios({
        url:   this.$baseUrl + "/tenantStore/allFacility",
        method: "GET"
      }).then(res => {
        if (res.data.rows) {
          this.facilityList = res.data.rows;
        }
      });
    },
    // 店铺状态
    getStoreStatus() {
      axios({
        url:   this.$baseUrl + "/tenantStore/allStoreStatus",
        method: "GET"
      }).then(res => {
        if (res.data.rows) {
          this.storeStatusList = res.data.rows;
        }
      });
    },
    // 店铺类型
    getStoreType() {
      axios({
        url:   this.$baseUrl + "/tenantStore/allStoreType",
        method: "GET"
      }).then(res => {
        if (res.data.rows) {
          this.storeTypeList = res.data.rows;
        }
      });
    },
    // 判断客户名称是否显示
    getIsPerson(val) {
      if (val == 1 || val == 2) {
        this.isPerson = true;
      } else {
        // 隐藏客户栏
        this.isPerson = false;
        // 清除客户栏的数据
        this.personList = [];
        const {
          form: { setFieldsValue }
        } = this;
        setFieldsValue({
          tenantId: "",
          kitchenIdList: []
        });
      }
    },
    // 客户名称
    getTenant(val) {
      this.personList = [];
      axios({
        url:   this.$baseUrl + "/tenantStore/tenant",
        method: "GET",
        params: {
          facilityId: val
        }
      }).then(res => {
        if (res.data.rows) {
          this.personList = res.data.rows;
        }
      });
    },
    // 根据所在门店+客户获取关联档口
    getKitchen1(val) {
      // 如果查询到的档口为空，清空它们之前的选中
      const {
        form: { setFieldsValue }
      } = this;
      setFieldsValue({
        kitchenIdList: []
      });
      axios({
        method: "GET",
        url:   this.$baseUrl + "/tenantStore/kitchenSelect",
        params: {
          facilityId: this.nowFacility,
          tenantId: val
        }
      }).then(res => {
        if (res.data.rows) {
          this.kitchList = res.data.rows;
        } else {
          this.$message.error(`${res.data.returnMsg}`);
        }
      });
    },
    //所在门店变化的时候
    getKitchen(val) {
      // 如果查询到的客户为空，清空它们之前的选中
      const {
        form: { setFieldsValue }
      } = this;
      setFieldsValue({
        tenantId: "",
        kitchenIdList: []
      });
      this.kitchList = [];
      this.nowFacility = val;
      this.getTenant(val);

      // 如果是我方虚拟自营，则在门店选择后调用档口接口
      if (!this.isPerson) {
        this.getKitchen1();
      }
    },
    onTabChange(key, type) {
      this[type] = key;
    },
    //新建品牌
    createStore(val) {
      axios({
        method: "POST",
        url:   this.$baseUrl + "/tenantStore/add",
        headers: {
          Accept: "*/*"
        },
        data: {
          ...val
        }
      })
        .then(res => {
          if (res.data.success) {
            this.$router.push({ path: "/role/shopList" });
            this.$message.success("新建店铺成功！");
          } else {
            this.$message.error(`该档口已存在客户自营店铺`);
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    conserve() {
      //点击保存
      let that = this;
      const {
        form: { validateFields }
      } = this;
      this.confirmLoading = true;
      validateFields((errors, values) => {
        if (!errors) {
          that.createStore(values);
        }
      });
    },
    cancel() {
      // 点击取消
      this.$router.push({ path: "/role/shopList" });
    }
  }
};
</script>

<style scoped>
.steps-action {
  float: right;
}
</style>
